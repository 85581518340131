/* BASE STYLES */
.overlay {
    background-color: var(--overlay);
    position: fixed;
    inset: 0;
    z-index: 4; /* Boost to 4 so that it does not effect mixed-blend-mode on pdp title and so it stacks on top of toolbars */
}

.root {
    --overflow-y: auto;
    position: fixed;
    top: 0;
    height: var(--height, 100%);
    background-color: var(--bg-color, var(--color-brand-white));
    z-index: 5;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    width: var(--width, 756px);
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    overflow-y: var(--overflow-y);
    border-top-right-radius: var(--top-radius, 0);
    border-top-left-radius: var(--top-radius, 0);
    -webkit-overflow-scrolling: touch !important;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overscroll-behavior-x: none;
}

@media screen {
    .root.cartNav,
    .root.catalogueNav,
    .root.trueFit {
        max-width: 504px;
    }
}

.root::-webkit-scrollbar {
    display: none;
}

.root:focus {
    outline: none;
}

.top {
    top: 0;
    width: 100%;
    height: initial;
}

.right {
    right: 0;
}

.bottom {
    top: unset;
    right: 0;
    bottom: 0;
}

.left {
    left: 0;
}

.close {
    display: none;
    margin-right: calc(var(--spacing-size-one) * -1);
}

.close svg {
    margin: 0;
    padding: 0;
    z-index: 2;
}

.header {
    --height: 96px;
    padding-block-start: var(
            --header-padding-block-start,
            var(--spacing-size-five)
    );
    padding-block-end: var(
            --header-padding-block-end,
            var(--spacing-size-five)
    );
    padding-inline-start: var(
            --header-padding-inline-start,
            var(--spacing-size-nine)
    );
    padding-inline-end: var(
            --header-padding-inline-end,
            var(--spacing-size-nine)
    );
    border-bottom: 1px solid var(--header-border-color, none);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--bg-color, var(--color-brand-white));
    z-index: 2;
    min-height: var(--height);
    max-height: var(--height);
}

.headerInner {
    display: flex;
    gap: var(--header-inner-gap, var(--spacing-size-two));
    width: var(--header-inner-width, 100%);
    align-items: center;
}

.body {
    padding-inline: var(--body-padding-inline, var(--spacing-size-nine));
    padding-block: var(--body-padding-block, 0);
    flex-grow: var(--flex-grow);
}

/* When the True Fit iframe is present,
remove padding from the drawer body */
.body:has(iframe) {
    padding: 0;
}

.footer {
    --footer-padding: var(--spacing-size-five) var(--spacing-size-nine);
    background-color: var(--footer-bg-color, var(--color-brand-white));
    padding: var(--footer-padding);
    margin-block-start: auto;
    border-top: 1px solid var(--footer-border-color, var(--color-brand-aluminium));
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 100%;
}

.footerInner {
    display: flex;
    justify-content: var(--footer-content-justification, space-between);
    align-items: var(--footer-item-alignment, center);
    flex-direction: var(--footer-flex-direction, row);
}

/* VARIANT STYLES */
.filters {
    --bg-color: var(--color-brand-cement);
    --footer-bg-color: var(--color-brand-cement);
    --footer-content-justification: end;
    --width: 504px;
    --footer-border-color: var(--color-brand-aluminium);
    --header-padding-inline-end: var(--spacing-size-eight);
}

.productAssistance {
    --bg-color: var(--color-brand-aluminium);
    --header-border-color: var(--color-brand-white);
    --body-padding-block: var(--fluid-32-72);
    --footer-bg-color: var(--color-brand-aluminium);
    --footer-border-color: var(--color-brand-steel);
    --footer-content-justification: end;
    --flex-grow: 1;
}

.catalogueNav {
    --body-padding-block: var(--spacing-size-nine) 0;
    --bg-color: var(--color-brand-cement);
    --footer-bg-color: var(--color-brand-cement);
    --footer-border-color: none;
    --footer-content-justification: none;
    --header-inner-gap: 0;
}

.catalogueNavDesktop {
    --overflow-y: visible;
    --body-padding-block: var(--spacing-size-seven) 0;
    --bg-color: var(--color-brand-cement);
    --footer-bg-color: var(--color-brand-cement);
    --footer-border-color: none;
    --footer-content-justification: none;
    --header-inner-gap: 0;
    --width: 504px;
}

.cartNav {
    --body-padding-block: var(--spacing-size-five) 0;
    --bg-color: var(--color-brand-cement);
    --footer-bg-color: var(--color-brand-cement);
    --footer-border-color: none;
    --footer-content-justification: none;
    --header-padding-inline-end: var(--spacing-size-eight) !important;
    --header-inner-gap: 0;
}

.lookbook {
    --bg-color: var(--color-brand-white);
}

.trueFit {
    --body-padding-inline: 0;
    --flex-grow: 1;
}

@media screen and (max-width: 1150px) {
    .catalogueNavDesktop {
        width: 100%;
    }

    .close {
        display: block;
    }
}

@media screen and (max-width: 991px) {
    .cartNav .body {
        padding-inline: var(--body-padding-inline, var(--spacing-size-five));
        padding-block: var(--body-padding-block, 0);
    }
}

@media screen and (max-width: 767px) {
    .root {
        --width: 100%;
        --height: 90%;
        --top-radius: 12px;
    }

    .catalogueNav,
    .searchPanel {
        --height: 100%;
        --top-radius: 0;
    }

    .header {
        --height: 80px;
        --header-padding-block-start: var(--spacing-size-four);
        --header-padding-block-end: var(--spacing-size-four);
        --header-padding-inline-start: var(--spacing-size-five);
        --header-padding-inline-end: var(--spacing-size-five);
        min-height: var(--height);
        max-height: var(--height);
        overflow-y: clip; /* Prevent unwanted scrolling on the y axis */
    }

    .body {
        padding-inline: var(--spacing-size-five);
    }

    .footer {
        --footer-padding: var(--spacing-size-five);
    }

    /* The ProductBuy component that is a child of the footer requires special padding conditions on the parent */
    .lookbook .footer {
        --footer-padding: 0;
    }

    .productAssistance .header {
        padding: 0;
    }

    .catalogueNav {
        --body-padding-block: var(--spacing-size-seven) 0;
    }
}

/* MOTION */
.overlay[data-state='closed'] {
    animation-name: fadeOut;
    animation-duration: 0.25s;
    animation-timing-function: ease-in-out;
}

.overlay[data-state='open'] {
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

.right[data-state='open'] {
    animation-name: slideInFromRight;
    animation-duration: 0.3s;
}

.right[data-state='closed'] {
    animation-name: slideOutFromRight;
    animation-duration: 0.25s;
}

.left[data-state='open'] {
    animation-name: slideInFromLeft;
    animation-duration: 0.3s;
}

.left[data-state='closed'] {
    animation-name: slideOutFromLeft;
    animation-duration: 0.25s;
}

.top[data-state='open'] {
    animation-name: slideInFromTop;
    animation-duration: 0.3s;
}

.top[data-state='closed'] {
    animation-name: slideOutFromTop;
    animation-duration: 0.25s;
}

.bottom[data-state='open'] {
    animation-name: slideInFromBottom;
    animation-duration: 0.3s;
}

.bottom[data-state='closed'] {
    animation-name: slideOutFromBottom;
    animation-duration: 0.25s;
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutFromRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutFromLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

@keyframes slideInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOutFromBottom {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}

@keyframes slideInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOutFromTop {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
